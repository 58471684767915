/* eslint-disable import/prefer-default-export */

export interface Visited {
  id: string;
  bilde: string;
  adresse: string;
  postnr: string;
  poststed: string;
  utbedringsplanId: string;
  // more data here...
}

export interface VisitedState {
  readonly data: Visited[];
  readonly pending: boolean;
  readonly error: Error;
}

export enum VisitedActionTypes {
  REQUEST = '@@visited/REQUEST',
  SUCCESS = '@@visited/SUCCESS',
  FAILURE = '@@visited/FAILURE',
  ADD = '@@visited/ADD',
}

interface VisitedRequest {
  type: VisitedActionTypes.REQUEST;
}

interface VisitedSuccess {
  type: typeof VisitedActionTypes.SUCCESS;
  data: [];
}

interface VisitedFailure {
  type: typeof VisitedActionTypes.FAILURE;
  error: Error;
}

interface VisitedRequestAdd {
  type: VisitedActionTypes.ADD;
}

export type VisitedActionCreators = VisitedRequest | VisitedSuccess | VisitedFailure | VisitedRequestAdd;
