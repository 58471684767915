/* eslint-disable import/prefer-default-export */

export interface Favorite {
  id: string;
  bilde: string;
  adresse: string;
  postnr: string;
  poststed: string;
  utbedringsplanId: string;
  // more data here...
}

export interface FavoritesState {
  readonly data: Favorite[];
  readonly pending: boolean;
  readonly error: Error;
}

export enum FavoritesActionTypes {
  REQUEST = '@@favorites/REQUEST',
  SUCCESS = '@@favorites/SUCCESS',
  FAILURE = '@@favorites/FAILURE',
  ADD = '@@favorites/ADD',
  REMOVE = '@@favorites/REMOVE',
}

interface FavoritesRequest {
  type: FavoritesActionTypes.REQUEST;
}

interface FavoritesSuccess {
  type: typeof FavoritesActionTypes.SUCCESS;
  data: [];
}

interface FavoritesFailure {
  type: typeof FavoritesActionTypes.FAILURE;
  error: Error;
}

interface FavoritesRequestAdd {
  type: FavoritesActionTypes.ADD;
}

interface FavoritesRequestRemove {
  type: FavoritesActionTypes.REMOVE;
}

export type FavoritesActionCreators =
  | FavoritesRequest
  | FavoritesSuccess
  | FavoritesFailure
  | FavoritesRequestAdd
  | FavoritesRequestRemove;
